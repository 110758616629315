import React from 'react';
import VolumeMediaIcon from './VolumeMediaIcon';

interface Props {
    strokeColor: string;
}

const AudioIcon: React.FC<Props> = ({ strokeColor }) => {
    return <VolumeMediaIcon strokeColor={strokeColor} />;
};

export default AudioIcon;
